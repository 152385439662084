import React, { useEffect, useState } from 'react';
import global from '../config/global';

export default function CostInfo({ organizationId, from, to, stopLoading }) {
  const [costInfo, setCostInfo] = useState({});
  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ organizationId, from, to }),
    };
    if (organizationId !== '') {
      fetch(`${global.URL}/api/client/organizationCost`, requestOptions)
        .then((res) => res.json())
        .then(
          (result) => {
            setCostInfo(result);
            stopLoading();
          },
          (error) => {
            console.log('ERROR AL TRAER DATA');
            stopLoading();
          }
        );
    }
  }, [from, organizationId, to]);
  return (
    <div className='flex'>
      <div className='border-2 rounded-lg border-white p-4 m-4'>
        <h2>Envios por campaña</h2>
        <span className='css-1axeus7 float-right'>{costInfo.campaignWA}</span>
      </div>
      <div className='border-2 rounded-lg border-white p-4 m-4'>
        <h2>Sesiones</h2>
        <span className='css-1axeus7 float-right'>{costInfo.conversationTotals}</span>
      </div>
      <div className='border-2 rounded-lg border-white p-4 m-4'>
        <h2>Total de Mensajes</h2>
        <span className='css-1axeus7 float-right'>{costInfo.totalMessages}</span>
      </div>
    </div>
  );
}
