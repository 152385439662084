import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner, Stack } from '@chakra-ui/react';

const Login = () => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading]);

  return (
    <div className='flex h-screen'>
      <Stack className='m-auto'>
        <Spinner className='m-auto' size='xl'/>
        <span className='font-bold'>Cargando</span>
      </Stack>
    </div>
  );
};
export default Login;
