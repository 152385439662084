import React, { useState } from 'react';
import { Input, Button } from '@chakra-ui/react';
import CostInfo from './costInfo';
import StatCard from './statCard';
import { IoRefresh } from 'react-icons/io5';

export default function ExtraInfo({ selectedOrganization, selectedChannel }) {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const [isLoading, setIsLoading] = useState(false);
  const [from, setFrom] = useState(`${year}-${month}-01`);
  const [to, setTo] = useState(`${year}-${month + 1}-01`);
  const [formValue, setFormValue] = useState({from: `${year}-${month}-01`, to: `${year}-${month + 1}-01`});

  const changeRange = () => {
    setIsLoading(true);
    setFrom(formValue.from);
    setTo(formValue.to);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  if (!selectedChannel) {
    return null;
  }

  return (
    <div>
      <div>
        <StatCard channelId={selectedChannel} from={formValue.from} to={formValue.to} className='mt-4' />
      </div>

      <div className='mt-7'>
        {selectedOrganization
          ? `Organización ${selectedOrganization.name}`
          : ''}
        <div className='flex mt-4'>
          <div>
            <h2>Consumo de la organización</h2>
            <div className='flex my-2'>
              <Input value={formValue.from} onChange={event => setFormValue({...formValue, from: event.target.value })} placeholder='Desde' className='mx-2' />
              <Input value={formValue.to} onChange={event => setFormValue({...formValue, to: event.target.value })} placeholder='Hasta' className='mx-2' />
              <Button
                isLoading={isLoading}
                loadingText='Cargando'
                colorScheme='blue'
                className='mx-2'
                width='300px'
                leftIcon={<IoRefresh />}
                onClick={() => changeRange()}
              >
                Actualizar
              </Button>
            </div>
            <CostInfo
              organizationId={selectedOrganization.id}
              from={from}
              to={to}
              className='mt-4'
              stopLoading={stopLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
