import React, { useState } from 'react';
import {
  useColorMode,
  Icon,
  Button,
  Drawer,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';
import { AiOutlineMenu } from 'react-icons/ai';
import {
  RiMoonClearLine,
  RiSunLine,
  RiSearchEyeLine,
  RiBillLine,
  RiLineChartLine,
} from 'react-icons/ri';
import Billing from './billing';
import Monitoring from './monitoring';
import Organizations from './organizations';

export default function Dashboard() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [route, setRoute] = useState('monitor');

  const changeRoute = (route) => {
    setRoute(route);
    onClose();
  };

  return (
    <>
      <div className='fixed top-0'>
        <Icon
          as={AiOutlineMenu}
          w={8}
          h={8}
          onClick={onOpen}
          className='cursor-pointer m-2'
        ></Icon>
      </div>
      <Drawer placement='left' onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth='1px'>
            Monitoreo Fusionix
            {colorMode === 'light' ? (
              <Button
                onClick={toggleColorMode}
                colorScheme='blue'
                className='float-right'
              >
                <Icon as={RiMoonClearLine} w={6} h={6} />
              </Button>
            ) : (
              <Button
                onClick={toggleColorMode}
                colorScheme='blue'
                className='float-right'
              >
                <Icon as={RiSunLine} w={6} h={6}></Icon>
              </Button>
            )}
          </DrawerHeader>
          <DrawerBody>
            <div
              onClick={() => changeRoute('monitor')}
              className='cursor-pointer my-2 p-2'
            >
              <Icon className='mr-3' as={RiSearchEyeLine} w={6} h={6} />{' '}
              Herramienta de monitoreo
            </div>
            <hr />
            <div
              onClick={() => changeRoute('billing')}
              className='cursor-pointer my-2 p-2'
            >
              <Icon className='mr-3' as={RiBillLine} w={6} h={6} /> Consumos de
              clientes
            </div>
            <hr />
            <div
              onClick={() => changeRoute('organizations')}
              className='cursor-pointer my-2 p-2'
            >
              <Icon className='mr-3' as={RiLineChartLine} w={6} h={6} /> Listado
              de organizaciones
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <div className='my-10 overflow-y-auto' style={{ height: '94vh' }}>
        {route === 'monitor' ? <Monitoring /> : route === 'billing' ? <Billing /> : <Organizations />}
      </div>
    </>
  );
}
