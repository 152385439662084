import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Input,
  Button,
  Select,
  useColorMode
} from '@chakra-ui/react';
import axios from 'axios';
import { ResponsivePie } from '@nivo/pie';
import BillRow from '../components/billRow';
import global from '../config/global';
import { IoRefresh } from 'react-icons/io5';

export default function Billing() {
  const { colorMode } = useColorMode();
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const clients = ['Edificar', 'Socialnet', 'Procoop', 'Colégio de Psicologos'];
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [chartDataCosts, setChartDataCosts] = useState([]);
  const [formValue, setFormValue] = useState({
    from: `${year}-${month}-01`,
    to: `${year}-${month + 1}-01`,
    client: 'Edificar',
  });

  const config = {
    pro: {
      cost: {
        campaign: 4.54,
        session: 5.59,
        extraOperator: 2000,
        organization: 39900,
      },
      includedSessions: 1000,
      includedWAMTs: 1000,
      includedSMSMTs: 50,
      operators: 15,
    },
    socialnet: {
      cost: {
        campaign: 4.54,
        session: 5.59,
        extraOperator: 2000,
        organization: 25000,
      },
      includedSessions: 1000,
      includedWAMTs: 1000,
      includedSMSMTs: 50,
      operators: 15,
    },
    basic: {
      cost: {
        campaign: 4.54,
        session: 5.59,
        extraOperator: 2000,
        organization: 9950,
      },
      includedSessions: 250,
      includedWAMTs: 250,
      includedSMSMTs: 50,
      operators: 5,
    },
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  const loadCostChart = (bill) => {
    let data = [];
    const orgCost = bill.reduce((previousValue, currentValue) => previousValue +
    (currentValue.concept.includes('Organización') ? currentValue.totalValue : 0), 0);
    if (orgCost > 0) {
      data.push({
        id: 'Organización',
        label: 'Organización',
        value: orgCost,
        color: '#0088FE',
      });
    }
    const SMSCost = bill.reduce((previousValue, currentValue) => previousValue +
    (currentValue.concept.includes('MTs de SMS') ? currentValue.totalValue : 0), 0);
    if (SMSCost > 0) {
      data.push({
        id: 'Envios SMS',
        label: 'Envios SMS',
        value: SMSCost,
        color: '#00C49F',
      });
    }
    const WAWebCost = bill.reduce((previousValue, currentValue) => previousValue +
    (currentValue.concept.includes('MTs de WhatsApp Web') ? currentValue.totalValue : 0), 0);
    if (WAWebCost > 0) {
      data.push({
        id: 'Envios WhatsApp Web',
        label: 'Envios WhatsApp Web',
        value: WAWebCost,
        color: '#FFBB28',
      });
    }
    const sessionCost = bill.reduce((previousValue, currentValue) => previousValue +
    (currentValue.concept === 'Sesiones excedentes' ? currentValue.totalValue : 0), 0);
    if (sessionCost > 0) {
      data.push({
        id: 'Sesiones',
        label: 'Sesiones',
        value: sessionCost,
        color: '#FF8042',
      });
    }
    const operatorCost = bill.reduce((previousValue, currentValue) => previousValue +
    (currentValue.concept.includes('Operadores') ? currentValue.totalValue : 0), 0);
    if (operatorCost > 0) {
      data.push({
        id: 'Operadores',
        label: 'Operadores',
        value: operatorCost,
        color: '#FF0000',
      });
    }
    setChartDataCosts(data);
  };

  const fetchData = () => {
    setIsLoading(true);
    axios
      .post(`${global.URL}/api/client/clientBill`, {
        client: formValue.client,
        from: formValue.from,
        to: formValue.to,
      })
      .then((clientBill) => {
        let bill = [];
        for (const org of clientBill.data) {
          bill.push({
            quantity: 1,
            concept: `Organización ${org.organization.name} - ${org.organization.plan}`,
            unitValue: config[org.organization.plan].cost.organization,
            totalValue: config[org.organization.plan].cost.organization,
          });
          const extraSessions =
            org.sessions - config[org.organization.plan].includedSessions > 0
              ? org.sessions - config[org.organization.plan].includedSessions
              : 0;
          const extraWAMts =
            org.WAMts - config[org.organization.plan].includedWAMTs > 0
              ? org.WAMts - config[org.organization.plan].includedWAMTs
              : 0;
          const extraSMSMts =
            org.SMSMts - config[org.organization.plan].includedSMSMTs > 0
              ? org.SMSMts - config[org.organization.plan].includedSMSMTs
              : 0;
          const extraOperators =
            org.operators - config[org.organization.plan].operators > 0
              ? org.operators - config[org.organization.plan].operators
              : 0;
          bill.push({
            quantity: extraSessions,
            concept: 'Sesiones excedentes',
            unitValue: config[org.organization.plan].cost.session,
            totalValue:
              extraSessions * config[org.organization.plan].cost.session,
          });
          bill.push({
            quantity: extraWAMts,
            concept: 'MTs de WhatsApp Web excedentes',
            unitValue: config[org.organization.plan].cost.campaign,
            totalValue:
              extraWAMts * config[org.organization.plan].cost.campaign,
          });
          bill.push({
            quantity: extraSMSMts,
            concept: 'MTs de SMS excedentes',
            unitValue: config[org.organization.plan].cost.campaign,
            totalValue:
              extraSMSMts * config[org.organization.plan].cost.campaign,
          });
          bill.push({
            quantity: extraOperators,
            concept: 'Operadores extras',
            unitValue: config[org.organization.plan].cost.extraOperator,
            totalValue:
              extraOperators * config[org.organization.plan].cost.extraOperator,
          });
        }
        setData(bill);
        loadCostChart(bill);
        stopLoading();
      });
  };

  return (
    <div className='p-4'>
      <div className='flex justify-center flex-wrap tablet:flex-nowrap max-w-7xl max-w-sm mx-auto'>
        <Input
          value={formValue.from}
          onChange={(event) =>
            setFormValue({ ...formValue, from: event.target.value })
          }
          placeholder='Desde'
          className='m-2'
        />
        <Input
          value={formValue.to}
          onChange={(event) =>
            setFormValue({ ...formValue, to: event.target.value })
          }
          placeholder='Hasta'
          className='m-2'
        />
        <Select
          className='m-2'
          onChange={(event) =>
            setFormValue({ ...formValue, client: event.target.value })
          }
        >
          {clients.map((client, i) => (
            <option value={client} key={i}>
              {client}
            </option>
          ))}
        </Select>
        <Button
          isLoading={isLoading}
          loadingText='Cargando'
          colorScheme='blue'
          className='m-2 ml-5'
          width='300px'
          leftIcon={<IoRefresh />}
          onClick={() => fetchData()}
        >
          Actualizar
        </Button>
      </div>
      <div className='max-w-7xl max-w-sm mx-auto'>
        <div className='overflow-x-auto'>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Cantidad</Th>
                <Th>Concepto</Th>
                <Th>Valor Unitario</Th>
                <Th>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((org, i) => (
                <BillRow data={org} key={i} />
              ))}
            </Tbody>
          </Table>
        </div>
        <div className='flex justify-between'>
          <span className='font-bold'>Total a pagar:</span>
          <span className='prototype tolo'>
            $
            {data
              .reduce(
                (previousValue, item) => previousValue + item.totalValue,
                0
              )
              .toLocaleString('es-AR')}
          </span>
        </div>
        <div className='flex justify-between w-100 h-96 text-black'>
          <ResponsivePie
            data={chartDataCosts}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor={colorMode === 'dark' ? '#FFF' : '#000'}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [['darker', 2]],
            }}
          />
        </div>
      </div>
    </div>
  );
}
