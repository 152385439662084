import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  useRadioGroup,
  HStack,
  useColorMode,
} from '@chakra-ui/react';
import { sub, getDate } from 'date-fns';
import { ResponsiveLine } from '@nivo/line';
import OrganizationRow from '../components/organizationRow';
import RadioCard from '../components/radioCard';
import global from '../config/global';

export default function Organizations() {
  const { colorMode } = useColorMode();
  const [data, setData] = useState([]);
  const [filteredOrgs, setFilteredOrgs] = useState([]);
  const [evolutionData, setEvolutionData] = useState([]);

  const options = ['Todas', '⭐ Nuevas', '🔥 Activas', '😴 Poca actividad'];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'orgState',
    defaultValue: options[0],
    onChange: (state) => {
      if (state === options[0]) {
        setFilteredOrgs(data);
      } else {
        const filtered = data.filter((org) => org.state === state);
        setFilteredOrgs(filtered);
      }
    },
  });

  const group = getRootProps();

  const fetchData = () => {
    axios.get(`${global.URL}/api/organization/evolution`).then((evolution) => {
      const result = evolution.data.map((item) => ({
        x: item.date.split('T')[0],
        y: item.orgs,
      }));
      console.log(result);
      setEvolutionData(result);
    });
    axios.get(`${global.URL}/api/organization/list`).then((organizations) => {
      let arr = [];
      for (let org of organizations.data) {
        if (org.conversations > 5 * getDate(new Date())) {
          org.state = options[2];
        } else if (new Date(org.created) >= sub(new Date(), { days: 15 })) {
          org.state = options[1];
        } else {
          org.state = options[3];
        }
        arr.push(org);
      }
      setData(arr);
      setFilteredOrgs(arr);
    });
  };

  useEffect(() => {
    fetchData();
    return () => {
      // Do something before unmounting
    };
  }, []);

  return (
    <div className='p-4'>
      <div className='max-w-7xl mx-auto'>
        <div style={{ height: '35vh' }}>
          <ResponsiveLine
            data={[
              {
                id: 'evolucion',
                data: evolutionData,
              },
            ]}
            margin={{ top: 50, right: 60, bottom: 50, left: 120 }}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
              precision: 'month',
            }}
            xFormat='time:%Y-%m-%d'
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'center',
              format: '%b %Y',
              legend: 'mes año',
              legendOffset: -10,
              legendPosition: 'middle',
            }}
            axisLeft={{
              orient: 'center',
              legend: 'Cant. Organizaciones',
              legendOffset: -35,
              legendPosition: 'middle',
            }}
            colors={{ scheme: 'dark2' }}
            enableGridX={false}
            pointSize={8}
            pointColor='white'
            theme={{
              textColor: colorMode === 'dark' ? '#FFF' : '#000',
              tooltip: { container: { color: '#000' } },
              crosshair: {
                line: {
                  stroke: colorMode === 'dark' ? '#FFF' : '#000',
                },
              },
            }}
            enableArea={true}
            areaOpacity={0.3}
            enableCrosshair={true}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            useMesh={true}
            legends={[]}
            motionConfig='stiff'
          />
        </div>
        <div className='overflow-x-auto'>
          Filtrar por Organizaciones:
          <HStack {...group} className='mt-2'>
            {options.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <RadioCard key={value} {...radio}>
                  {value}
                </RadioCard>
              );
            })}
          </HStack>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Logo</Th>
                <Th>Nombre</Th>
                <Th>Creación</Th>
                <Th>Plan</Th>
                <Th>Conv. del mes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredOrgs.map((organization, i) => (
                <OrganizationRow data={organization} key={i} />
              ))}
            </Tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
