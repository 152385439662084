import React from 'react';
import { Tr, Td, Tooltip } from '@chakra-ui/react';
import Battery from './battery';

export default function ChannelRow({ data, changeChannel }) {
  return (
    <Tr className='cursor-pointer' onClick={() => changeChannel(data.Id, {name: data.OrganizationName, id: data.OrganizationId})}>
      <Td>
        <Tooltip label={`Creado: ${data.Created}`} fontSize='md'>
          {data.Name}
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={`ChannelId: ${data.Id}`} fontSize='md'>
          {data.msisdn}
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={`Instancia: ${data.instance}`} fontSize='md'>
        {data.OrganizationName}
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={`Estado: ${data.State}`} fontSize='md'>
          {data.State === 1 ? 'Activo' : 'Deshabilitado'}
        </Tooltip>
      </Td>
      <Td>
        {data.Battery ? <><Battery battery={data.battery} /> {data.battery}%</> : <></>}
      </Td>
      <Td>
        {data.connectionState === 'CONNECTED' ? 'Conectado' : 'Desconectado'}
      </Td>
      <Td style={data.queue.active >= 7 || data.queue.dead >= 7 ? {color: '#f44336'} : {color: '#4bdb10'}}>
        {`${data.queue.active} / ${data.queue.dead}`}
      </Td>
      <Td>{data.waWebVersion}</Td>
    </Tr>
  );
}
