import React from 'react';
import { Tr, Td } from '@chakra-ui/react';
import { format } from 'date-fns';

export default function OrganizationRow({ data }) {
  const getOrgStateIcon = () => {
    return data.state.split(' ')[0];
  };
  
  return (
    <Tr>
      <Td className='flex'>
        <span className='mt-2 mr-2' style={{fontSize: '1.3rem'}}>{getOrgStateIcon()}</span>
        <img
          className='h-10 w-10 rounded-full ring-2 ring-gray'
          src={
            data.logo
              ? data.logo
              : 'https://omnichatprod.blob.core.windows.net/upload/channel/96059f55-868f-4a70-8c96-7aa6ad3ec252.png'
          }
          alt='organization-logo'
        />
      </Td>
      <Td>
        {data.name}
      </Td>
      <Td>{format(new Date(data.created), 'dd/MM/yyyy')}</Td>
      <Td>{data.plan}</Td>
      <Td>{data.conversations}</Td>
    </Tr>
  );
}
