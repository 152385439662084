import React from 'react';
import { Icon } from '@chakra-ui/react';
import { IoBatteryFull, IoBatteryHalf, IoBatteryDead } from 'react-icons/io5';

export default function Battery({ battery }) {
  if (battery >= 60) {
    return <Icon as={IoBatteryFull} w={6} h={6} color='#4bdb10' />;
  } else if (battery >= 25) {
    return <Icon as={IoBatteryHalf} w={6} h={6} color='#ffeb3b' />;
  } else {
    return <Icon as={IoBatteryDead} w={6} h={6} color='#f44336' />;
  }
}
