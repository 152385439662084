import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import Dashboard from './pages/dashboard';
import Login from './pages/login';

const theme = extendTheme({
  colors: {
    gray: {
      800: '#222831',
    },
  },
  config: {
    useSystemColorMode: true,
  },
});

function App() {
  const { isAuthenticated } = useAuth0();
  return (
    <ChakraProvider theme={theme}>
      {!isAuthenticated ? <Login /> : <Dashboard />}
    </ChakraProvider>
  );
}

export default App;
