import React, { useEffect, useState } from 'react';
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from '@chakra-ui/react';
import global from '../config/global';

export default function StatCard({ channelId, from, to }) {
  const [campaignInfo, setCampaignInfo] = useState({});
  const [previousInfo, setPreviousInfo] = useState({});
  useEffect(() => {
    if (channelId !== '') {
      fetch(`${global.URL}/api/client/${channelId}/${from}/${to}`)
        .then((res) => res.json())
        .then(
          (result) => {
            setPreviousInfo(campaignInfo);
            setCampaignInfo(result);
          },
          (error) => {
            console.log('ERROR AL TRAER DATA');
          }
        );
    }
  }, [channelId]);
  if(campaignInfo.campaignTotal !== 0) {
    return (
      <>
        <h2 className='mb-4'>Detalle de las campaña del canal</h2>
        <div className='w-96 border-2 rounded-lg border-white p-4'>
          <StatGroup>
            <Stat>
              <StatLabel>Total</StatLabel>
              <StatNumber>{campaignInfo.campaignTotal}</StatNumber>
            </Stat>
  
            <Stat>
              <StatLabel>Enviados</StatLabel>
              <StatNumber>{campaignInfo.sent}</StatNumber>
              <StatHelpText>
                <StatArrow
                  type={
                    previousInfo.sent > campaignInfo.sent
                      ? 'decrease'
                      : 'increase'
                  }
                />
                {previousInfo.sent ? (campaignInfo.sent - previousInfo.sent) : 0}
              </StatHelpText>
            </Stat>
  
            <Stat className='text-center'>
              <StatLabel>Entregados</StatLabel>
              <StatNumber>{campaignInfo.delivered}</StatNumber>
              <StatHelpText>
                <StatArrow
                  type={
                    previousInfo.delivered > campaignInfo.delivered
                      ? 'decrease'
                      : 'increase'
                  }
                />
                {previousInfo.delivered? (campaignInfo.delivered - previousInfo.delivered) : 0}
              </StatHelpText>
            </Stat>
  
            <Stat className='text-right'>
              <StatLabel>Rechazados</StatLabel>
              <StatNumber>{campaignInfo.refused}</StatNumber>
              <StatHelpText>
                <StatArrow
                  type={
                    previousInfo.refused > campaignInfo.refused
                      ? 'decrease'
                      : 'increase'
                  }
                />
                {previousInfo.refused ? (campaignInfo.refused - previousInfo.refused) : 0}
              </StatHelpText>
            </Stat>
          </StatGroup>
        </div>
      </>
    );
  }
  return (<></>);
}
