import React from 'react';
import { Tr, Td } from '@chakra-ui/react';

export default function BillRow({ data }) {
  if(data.quantity > 0) {
    return (
        <Tr>
          <Td>
              {data.quantity}
          </Td>
          <Td>
              {data.concept}
          </Td>
          <Td>
              ${data.unitValue.toLocaleString('es-AR')}
          </Td>
          <Td>
              ${data.totalValue.toLocaleString('es-AR')}
          </Td>
        </Tr>
      );
  }
  return <></>;
}
