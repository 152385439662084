import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Spinner } from '@chakra-ui/react';
import axios from 'axios';
import ChannelRow from '../components/channelRow';
import ExtraInfo from '../components/extraInfo';
import global from '../config/global';

export default function Monitoring() {
  const [data, setData] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('');
  const [selectedOrganization, setOrganization] = useState({});

  const changeChannel = (channelId, organization) => {
    setSelectedChannel(channelId);
    setOrganization(organization);
  };

  const fetchData = () => {
    axios
      .get(`${global.URL}/api/client/list`)
      .then((channels) => setData(channels.data.channelList));
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 5 * 60000);
    return () => {
      // Do something before unmounting
      clearInterval(interval);
    };
  }, []);

  return (
    <div className='p-4'>
      <div className='max-w-7xl mx-auto'>
        <div className='overflow-x-auto'>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Nombre</Th>
                <Th>Teléfono</Th>
                <Th>Organización</Th>
                <Th>Estado Fusionix</Th>
                <Th>Bateria</Th>
                <Th>Conexión</Th>
                <Th>Queue</Th>
                <Th>Versión WaW</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((channel, i) => (
                <ChannelRow
                  data={channel}
                  changeChannel={changeChannel.bind(this)}
                  key={i}
                />
              ))}
            </Tbody>
          </Table>
        </div>
        {!data || data.length === 0 ? (
          <div className='flex'>
            <Spinner className='m-auto' size='xl' />
          </div>
        ) : (
          ''
        )}
        <ExtraInfo
          selectedChannel={selectedChannel}
          selectedOrganization={selectedOrganization}
        ></ExtraInfo>
      </div>
    </div>
  );
}
